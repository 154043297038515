import React from 'react'
import SEO from '../../components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import { Section } from '../../components/styled/section'
import styled from 'styled-components'
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

const ButtonNewPage = styled.div`
  text-decoration: underline;
  //margin: 15px;
  &:hover {
    cursor: pointer;
    text-decoration: underline #1a51a0;
  }
`

const digitalservicehefte = () => {
  const title = 'Digitalservicehefte'
  return (
    <SubPageLayout title={title}>
      <SEO title={title} />
      <Section>
        <Text>
          <h4>Digitalservicehefte</h4>
          <p>
            Det digitale servicehefte inneholder alle opplysninger om hva som er gjort med et
            konkret kjøretøy. Her finner du servicer og alt annet som er utført på dette kjøretøyet
            utført hos et BilXtra verksted. Over 200 stk av Bilxtra sine verkstedene benytter Cars
            systemet som gjør dette mulig. I tillegg vil du kunne se diverse teknisk informasjon om
            din bil.
          </p>
          <h4>Fordeler med digitalt:</h4>
          <ul>
            <li>Papirhefter finnes nesten ikke i nye biler</li>
            <li>Digitalt inneholder også andre jobber i tillegg til service</li>
            <li>Ved kjøp og salg av kjøretøy</li>
            <li>Ved reklamasjon</li>
            <li>Ved dokumentasjon mot andre aktører</li>
            <li>Felles historikk ved bruk av flere Bilxtra verksted</li>
            <li>Full historikk hele døgnet og uansett hvor du er</li>
            <li>Tilgjengelig for andre Bilxtra verksted</li>
          </ul>
        </Text>

        <ButtonNewPage
          onClick={(e) => {
            e.preventDefault()

            window.open('https://digitalservicehefte.no/', '_blank')
          }}
        >
          Trykk her for og gå til Digitalservicehefte
        </ButtonNewPage>
      </Section>
    </SubPageLayout>
  )
}

export default digitalservicehefte
